import {Box, Grid, GridItem} from "@chakra-ui/react";
import {NewsByCategory} from "../../types";
import React, {useEffect} from "react";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {fetchTopNewsByCategory} from "../../store/topNewsByCategory/topNewsByCategorySlice";
import NewsByCategoryDisplay from "./NewsByCategoryDisplay";


const NewsDisplay = ( ) => {
    const dispatch = useAppDispatch();
    const categories: NewsByCategory[] = useAppSelector((state: RootState) => state.topNewsByCategory.data);
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    useEffect(() => {
        dispatch(fetchTopNewsByCategory({ locale, page: 0 }));
    }, [dispatch, locale]);

    return (
        <Box mt={8}>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                {
                    categories.map((category: NewsByCategory) => (
                        <GridItem key={category.categoryCode} borderBottom={"1px"}>
                            <NewsByCategoryDisplay category={category} />
                        </GridItem>
                    ))
                }
            </Grid>
        </Box>
    );
};

export default NewsDisplay;
