import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LocaleState {
    locale: string;
}

const initialState: LocaleState = {
    locale: localStorage.getItem('locale') || navigator.language.split(/[-_]/)[0],
};

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLocale(state, action: PayloadAction<string>) {
            state.locale = action.payload;
            localStorage.setItem('locale', action.payload); // Save the locale in localStorage
        },
    },
});

export const { setLocale } = localeSlice.actions;

export const selectLocale = (state: LocaleState) => state.locale;

export default localeSlice.reducer;
