import React, {useEffect, useState} from "react";
import {Box, Heading, Image} from "@chakra-ui/react";

export const CarouselItem = ({ item }: any) => {
    const [imageError, setImageError] = useState(false);
    const [bgColor, setBgColor] = useState("");

    // Function to generate a random color
    const generateRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    useEffect(() => {
        if (imageError) {
            setBgColor(generateRandomColor());
        }
    }, [imageError]);

    return (
        <Box p={4} position="relative">
            <Box position="relative">
                {!imageError ? (
                    <Image
                        src={item.imageUrl}
                        alt={item.title}
                        objectFit="cover"
                        h="400px"
                        w="100%"
                        onError={() => setImageError(true)}
                    />
                ) : (
                    <Box
                        h="400px"
                        w="100%"
                        bg={bgColor}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Heading as="h3" fontSize="lg" color="white" textAlign="center">
                            {item.title}
                        </Heading>
                    </Box>
                )}
                <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    h="50%"
                    bgGradient="linear(to-t, rgba(0, 0, 0, 0.9), transparent)"
                />
                {!imageError && (
                    <Heading
                        as="h3"
                        fontSize="lg"
                        color="white"
                        position="absolute"
                        bottom="10px"
                        textAlign="left"
                        left="10px"
                        fontStyle="italic"
                        noOfLines={2}
                        zIndex={1}
                    >
                        {item.title}
                    </Heading>
                )}
            </Box>
        </Box>
    );
};