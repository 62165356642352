import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch"; // Replace useDispatch with useAppDispatch
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    Icon,
    Center,
    Stack,
    useTheme,
    FormControl,
    FormLabel,
    useBreakpointValue
} from "@chakra-ui/react";
import { signInUser, googleSignInUser, facebookSignInUser, appleSignInUser } from "../../store/auth/authSlice"; // Redux actions
import { FaFacebook } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import useCustomToast from "../../hooks/useCustomToast";
import {fetchUserDetails} from "../../store/user/userSlice";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";

export const Login = () => {
    const theme = useTheme();
    const accentColor = theme.colors.gray;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useAppDispatch(); // Typed version of useDispatch
    const showToast = useCustomToast();
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const user = useAppSelector((state: RootState) => state.user.user);

    const handleGoogleSignIn = () => {
        dispatch(googleSignInUser()).unwrap()
            .then(() => navigate("/"))
            .catch((error: any) => {
                
                console.error(error);
                showToast({
                    title: "Error!",
                    description: "Failed to sign in with Google.",
                    status: "error",
                    position: "bottom-right"
                });
            });
    };

    const handleFacebookSignInUser = () => {
        dispatch(facebookSignInUser()).unwrap()
            .then(() => navigate("/"))
            .catch((error: any) => {
                console.error(error);
                showToast({
                    title: "Error!",
                    description: "Failed to sign in with Facebook.",
                    status: "error",
                    position: "bottom-right"
                });
            });
    };

    const handleAppleSignInUser = () => {
        dispatch(appleSignInUser()).unwrap()
            .then(() => navigate("/"))
            .catch((error: any) => {
                console.error(error);
                showToast({
                    title: "Error!",
                    description: "Failed to sign in with Apple.",
                    status: "error",
                    position: "bottom-right"
                });
            });
    };

    const handleSignInClick = async () => {
        try {
            await dispatch(signInUser({ username: email, password: password })).unwrap();
            const user = await dispatch(fetchUserDetails(locale)).unwrap();

            if (user != null) {
                if (user.isNewUser === false) {
                    navigate("/");
                } else {
                    navigate("/complete-profile");
                }
                showToast({
                    title: "Success!",
                    description: "Signed in successfully.",
                    status: "success",
                    position: "bottom-right",
                });
            } else {
                throw new Error("User not found");
            }
        } catch (error: any) {
            const errorMsg = error.message || "Invalid credentials.";
            showToast({
                title: "Error!",
                description: errorMsg,
                status: "error",
                position: "bottom-right",
            });
        }
    };


    // Responsive values
    const containerWidth = useBreakpointValue({ base: "90%", md: "75%", lg: "50%" });
    const boxPadding = useBreakpointValue({ base: "10px", md: "20px" });
    const formSpacing = useBreakpointValue({ base: 4, md: 6 });
    const buttonSize = useBreakpointValue({ base: "full", md: "md" });

    return (
        <Box width={containerWidth} margin="0 auto" padding="20px">
            <Box
                width="100%"
                margin="0 auto"
                border={`3px solid ${theme.colors.gray[300]}`}
                borderRadius="20px"
                padding={boxPadding}
                maxWidth="500px"
            >
                <Text fontSize="24px" textAlign="center" mb={6}>
                    <FormattedMessage id="welcome" defaultMessage="Welcome" />
                </Text>
                <Center marginBottom={6}>
                    <Stack spacing={2} align={'center'} w={'full'}>
                        <Button
                            w={buttonSize}
                            variant={'outline'}
                            leftIcon={<FcGoogle />}
                            onClick={handleGoogleSignIn}
                        >
                            <Text>
                                <FormattedMessage id="googleSignIn" defaultMessage="Sign in with Google" />
                            </Text>
                        </Button>

                        <Button
                            w={buttonSize}
                            variant={'outline'}
                            leftIcon={<FaFacebook color={"blue.400"} />}
                            onClick={handleFacebookSignInUser}
                        >
                            <Text>
                                <FormattedMessage id="facebookSignInUser" defaultMessage="Sign in with Facebook" />
                            </Text>
                        </Button>

                        <Button
                            w={buttonSize}
                            variant={'outline'}
                            leftIcon={<AiFillApple />}
                            onClick={handleAppleSignInUser}
                        >
                            <Text>
                                <FormattedMessage id="appleSignInUser" defaultMessage="Sign in with Apple" />
                            </Text>
                        </Button>
                    </Stack>
                </Center>
                <Box mt={8}>
                    <Stack spacing={formSpacing}>
                        <FormControl id="email">
                            <FormLabel><FormattedMessage id="emailAddress" defaultMessage="Email Address" /></FormLabel>
                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel><FormattedMessage id="password" defaultMessage="Password" /></FormLabel>
                            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </FormControl>
                        <Stack spacing={10}>
                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                align={'start'}
                                justify={'space-between'}>
                                <Text color={'grey.200'} fontSize="15px" onClick={() => navigate("/forgot-password")}>
                                    <FormattedMessage id="forgotPassword" defaultMessage="Forgot Password?" />
                                </Text>
                            </Stack>
                            <Button
                                bg={'blue.400'}
                                color={'white'}
                                onClick={handleSignInClick}
                                _hover={{
                                    bg: 'blue.500',
                                }}>
                                <FormattedMessage id="signin" defaultMessage="Sign In" />
                            </Button>
                        </Stack>
                    </Stack>
                    <Text color="#6c798f" textAlign="center" fontSize="15px" mt={6}>
                        <FormattedMessage id="newToNisee" defaultMessage="New to NiSee?" />
                    </Text>
                    <Text
                        color="rgb(69, 112, 216)"
                        fontWeight="700"
                        fontSize="15px"
                        textAlign="center"
                        cursor="pointer"
                        mb={6}
                        onClick={() => navigate("/register")}
                    >
                        <FormattedMessage id="createAccount" defaultMessage="Create an account to get started" />
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};
