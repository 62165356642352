import {
    Box,
    Heading,
    Flex,
} from '@chakra-ui/react';


import {FormattedMessage} from "react-intl";
import React, {useEffect} from "react";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {fetchUserDetails} from "../../store/user/userSlice";

function FollowingStep() {
    const dispatch = useAppDispatch();

    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const user = useAppSelector((state: RootState) => state.user.user);



    useEffect(() => {
        if (!user) {
            dispatch(fetchUserDetails(locale));
        }
    }, [dispatch, user]);

    return (
        <Flex justifyContent="center" alignItems="center">
            <Box
                textAlign="left"
            >
                <Heading size="lg" mb={4}>
                    <FormattedMessage id={"suggested_follows"} defaultMessage={"Suggested follows"}/>
                </Heading>
                <Box mb={6}>
                    <FormattedMessage id={"kickstart_community"} defaultMessage={"Kickstart your community"}/>
                </Box>

            </Box>
        </Flex>
    );
}

export default FollowingStep;
