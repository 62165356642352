import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import {News} from "../../types";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import {fetchAuthHeaders} from "../../utils/authHeaders"; // Assuming you have a RootState type from your store

// Initial state for post slice
interface PostState {
    posts: News[];
    loading: boolean;
    error: string | null;
}

const initialState: PostState = {
    posts: [],
    loading: false,
    error: null,
};

// export const fetchPostsOfUser = createAsyncThunk(
//     'post/fetchPostsOfUser',
//     async (userId: string, {  dispatch, rejectWithValue, getState }) => {
//         try {
//             const state = getState() as RootState;
//             const accessToken = state.auth.idToken;
//             const headers = accessToken ? { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' } : {};
//
//             const response = await axios.get(
//                 `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080'}/api/v1/news/user/${userId}`,
//                 {headers: headers}
//             );
//             return response.data;
//         } catch (error: any) {
//             return rejectWithValue(error.response.data.message || 'Failed to fetch posts');
//         } finally {
//             dispatch(stopLoading());
//         }
//     }
// );


export const fetchPostsOfCurrentUser = createAsyncThunk(
    'post/fetchPostsOfCurrentUser',
    async (_, {  dispatch, rejectWithValue, getState  }) => {
        try {
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/news/user_posts`, {},{
                headers: headers,
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to fetch posts');
        } finally {
            dispatch(stopLoading());
        }
    }
);

// Create the post slice
const userPostSlice = createSlice({
    name: 'userPosts',
    initialState,
    reducers: {
        setPost(state, action: PayloadAction<News[]>) {
            state.posts = action.payload;
        },
        clearPost(state) {
            state.posts = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPostsOfCurrentUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPostsOfCurrentUser.fulfilled, (state, action: PayloadAction<News[]>) => {
                state.loading = false;
                state.posts = action.payload;
                state.error = null;
            })
            .addCase(fetchPostsOfCurrentUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
    },
});

// Export the action creators and reducer
export const { setPost, clearPost } = userPostSlice.actions;
export default userPostSlice.reducer;

// Selector to access the post state in your components
export const selectPosts = (state: RootState) => state.userPosts.posts;
export const selectLoading = (state: RootState) => state.userPosts.loading;
export const selectError = (state: RootState) => state.userPosts.error;
