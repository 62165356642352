import {Grid, Box, Button, Text, Flex, Heading} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import TagBox from "../../components/TagBox";
import {FormattedMessage} from "react-intl";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {fetchTags} from "../../store/tags/tagSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {Tag, User} from "../../types";


interface TagSelectionStepProps {
    handleStepDataChange: (updatedData: Partial<User>) => void
}

function TagSelectionStep({handleStepDataChange}: TagSelectionStepProps) {
    const dispatch = useAppDispatch();
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const tags = useAppSelector((state: RootState) => state.tag.tags);
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    const handleTagSelect = (tag: Tag, isSelected: boolean) => {
        if (isSelected) {
            setSelectedTags([...selectedTags, tag]);
        } else {
            setSelectedTags(selectedTags.filter((t) => t !== tag));
        }

        handleStepDataChange({ interests: selectedTags });
    };

    useEffect(() => {
        if (locale && tags.length === 0) {
            dispatch(fetchTags(locale));
        }
    }, [dispatch, locale]);

    return (
        <Flex justifyContent="center" alignItems="center">
            <Box
                textAlign="left"
            >

                <Heading size="lg" mb={4}>
                    <FormattedMessage id={"what_interests_you"} defaultMessage={"What are you interested in?"}/>
                </Heading>
                <Box mb={6}>
                    <FormattedMessage id={"follow_tags"}
                                      defaultMessage={"Follow tags to get personalized recommendations"}/>
                </Box>
                <Text mb={4}>
                    {selectedTags.length} <FormattedMessage id={"tags_selected"} defaultMessage={"tags selected"}/>
                </Text>
                <Grid templateColumns={{base: "repeat(2, 1fr)", md: "repeat(3, 1fr)"}} gap={4}>
                    {tags.map((t) => (
                        <TagBox
                            key={t.code}
                            tag={t}
                            isSelected={selectedTags.includes(t)}
                            onSelectTag={handleTagSelect}
                        />
                    ))}
                </Grid>
            </Box>
        </Flex>
    );
};

export default TagSelectionStep;
