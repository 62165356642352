import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import React from "react";

export const TitleToolTip = () => {
    return (
        <Box>
            <Heading as="h4" size="md" mb={3}>
                Writing a Great Post Title
            </Heading>
            <UnorderedList ml={4} listStyleType="disc">
                <ListItem>
                    <Text fontSize="sm" textAlign="justify">
                        Think of your post title as a super short (but compelling!) description — like an overview of the actual post in one short sentence.
                    </Text>
                </ListItem>
                <ListItem>
                    <Text fontSize="sm" textAlign="justify">
                        Use keywords where appropriate to help ensure people can find your post by search.
                    </Text>
                </ListItem>
            </UnorderedList>
        </Box>
    );
};
