import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {Country} from "../../types";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import {fetchAuthHeaders} from "../../utils/authHeaders";

interface LocaleState {
    countries: Country[];
    isLoading: boolean;
    error: string | null;
}

const initialState: LocaleState = {
    countries: [],
    isLoading: false,
    error: null,
};

export const fetchCountries = createAsyncThunk(
    'country/fetchCountries',
    async (locale: string, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/countries?locale=${locale}`,{
                headers: headers,
            });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to fetch countries');
        }
        finally {
            dispatch(stopLoading());
        }
    }
);

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountries.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchCountries.fulfilled, (state, action: PayloadAction<Country[]>) => {
                state.isLoading = false;
                state.countries = action.payload;
            })
            .addCase(fetchCountries.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
    },
});


export default countrySlice.reducer;
