import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Input
} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";

interface SearchDrawerProps {
    onClose: () => void;
    isOpen: boolean;
}

export const SearchDrawer: React.FC<SearchDrawerProps> = ({onClose, isOpen}) => {
    return (
        <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader><FormattedMessage id="search" defaultMessage="Search"/></DrawerHeader>
                <DrawerBody>
                    <Input placeholder="Search..." />
                    {/* Add your search functionality here */}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
