import React, { useCallback, useState, useEffect } from "react";
import { Box, VStack, Text, Image, IconButton, Progress, useToast } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { CloseIcon } from "@chakra-ui/icons";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { resetUploadState, uploadToS3 } from "../../store/s3/s3Slice";

interface FileUploadProps {
    onUploadSuccess?: (fileUrl: string) => void; // Callback for successful upload
    maxSize?: number; // File size limit in bytes
}

const FileUpload: React.FC<FileUploadProps> = ({ onUploadSuccess, maxSize = 5000000 }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const dispatch = useAppDispatch();
    const { loading, error, uploadedUrl } = useAppSelector((state) => state.s3);
    const toast = useToast();

    // Show toast notifications for success or error
    useEffect(() => {
        if (uploadedUrl) {
            toast({
                title: "File uploaded successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            if (onUploadSuccess) onUploadSuccess(uploadedUrl);
            setUploadProgress(0); // Reset progress after successful upload
        }
        if (error) {
            toast({
                title: "Upload failed",
                description: error,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            setUploadProgress(0); // Reset progress on error
        }
    }, [uploadedUrl, error, toast, onUploadSuccess]);

    // Reset the upload state when the component unmounts
    useEffect(() => {
        return () => {
            dispatch(resetUploadState());
        };
    }, [dispatch]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];
            if (file && file.size <= maxSize) {
                setSelectedFile(file);
                dispatch(resetUploadState()); // Reset previous upload state

                // Start uploading the file
                dispatch(uploadToS3(file)).unwrap()
                    .then((response) => {
                        setUploadProgress(100); // Set progress to 100% on successful upload
                    })
                    .catch(() => {
                        console.error("Upload failed");
                    });
            } else {
                toast({
                    title: "File is too large",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
            }
        },
        [maxSize, dispatch, toast]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { "image/*": [".png", ".jpg", ".jpeg"] },
        maxFiles: 1,
    });

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setUploadProgress(0);
        dispatch(resetUploadState()); // Reset state in store
    };

    return (
        <VStack>
            {uploadedUrl ? (
                // Show the uploaded image preview
                <Box position="relative" mt={4}>
                    <Image
                        src={uploadedUrl}
                        alt="Uploaded preview"
                        boxSize="200px"
                        objectFit="cover"
                        borderRadius="md"
                        shadow="sm"
                    />
                    <IconButton
                        aria-label="Remove file"
                        icon={<CloseIcon />}
                        position="absolute"
                        top="5px"
                        right="5px"
                        onClick={handleRemoveFile}
                        colorScheme="red"
                        size="sm"
                    />
                </Box>
            ) : (
                <Box
                    {...getRootProps()}
                    border="2px dashed"
                    borderColor="gray.300"
                    p={6}
                    rounded="md"
                    bg={isDragActive ? "gray.100" : "gray.50"}
                    w="100%"
                    maxW="lg"
                    cursor="pointer"
                    position="relative"
                >
                    <input {...getInputProps()} />
                    {selectedFile ? (
                        <>
                            <Progress value={uploadProgress} size="sm" colorScheme="blue" mt={2} />
                            <IconButton
                                aria-label="Remove file"
                                icon={<CloseIcon />}
                                position="absolute"
                                top="5px"
                                right="5px"
                                onClick={handleRemoveFile}
                                colorScheme="red"
                                size="sm"
                            />
                        </>
                    ) : (
                        <Text>{isDragActive ? "Drop file here..." : "Drag and drop file here, or click to select"}</Text>
                    )}
                </Box>
            )}
        </VStack>
    );
};

export default FileUpload;
