import React from 'react';
import {Button, useColorModeValue, useTheme} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import {useNavigate} from "react-router-dom";


export const AuthButton = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const color = useColorModeValue(theme.colors.gray[500], "white");
    const textColor = useColorModeValue("black", "white");
    const hoverColor = useColorModeValue("light.primary", "dark.primary");

    const handleLoginClick = () => navigate("/login")

    return (
        <Button
            onClick={handleLoginClick}
            backgroundColor={"transparent"}
            color={color}
            size={"sm"}
            borderRadius="5px"
            textColor={textColor}
        >
            <FormattedMessage id="login" defaultMessage="Sign in" />
        </Button>
    );
};
