import { RootState } from '../store/store';
import { refreshIdToken } from '../store/auth/authSlice';
import {ThunkDispatch, UnknownAction} from "@reduxjs/toolkit"; // Import AppDispatch

export async function fetchAuthHeaders(dispatch:  ThunkDispatch<unknown, unknown, UnknownAction>, getState: () => unknown): Promise<Record<string, string>> {
    await dispatch(refreshIdToken());

    const state = getState() as RootState;
    const accessToken = state.auth.idToken;
    return accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        }
        : {};
}
