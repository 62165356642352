import React, {useEffect} from "react";
// @ts-ignore
import Slider from "react-slick";
import {Box, Heading, useColorModeValue, Divider} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {RootState} from "../../store/store";
import {fetchTopNews} from "../../store/topNews/topNewsSlice";
import {FormattedMessage} from "react-intl";
import {CarouselItem} from "./CarouselItem";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";

const CustomArrow = ({ className, style, onClick, direction, color }: any) => (
    <Box
        className={className}
        onClick={onClick}
        style={{
            ...style,
            display: "block",
            background: "transparent",
            color: color,
            position: "absolute",
            top: "50%",
            zIndex: 2,
            [direction === "left" ? "left" : "right"]: "-25px",
        }}
    >
        {direction === "left" ? (
            <ChevronLeftIcon w={8} h={8}/>
        ) : (
            <ChevronRightIcon w={8} h={8} />
        )}
    </Box>
);

const TopPicksCarousel = () => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const news = useAppSelector((state: RootState) => state.topNews.data);

    const arrowColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.100');
    const primaryColor = useColorModeValue('light.primary', 'dark.primary');

    useEffect(() => {
        dispatch(fetchTopNews({ locale, page: 0 }));
    }, [dispatch, locale]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <CustomArrow direction="right" color={arrowColor} />,
        prevArrow: <CustomArrow direction="left" color={arrowColor} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    return (
        <Box>
            <Heading as="h2" fontSize="2xl" mb={4} mt={4} textAlign="left" >
                <FormattedMessage id="topPicks" defaultMessage="Top Picks"/>
            </Heading>
        <Box maxW="container.xl" mx="auto" mt={8}>
            <Slider {...settings}>
                {news.map((item, index) => (
                    <CarouselItem key={index} item={item} />
                ))}
            </Slider>
        </Box>
        </Box>
    );
};

export default TopPicksCarousel;
