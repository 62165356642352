import React, { useState, useEffect } from 'react';
import { Box, Grid, Input, Button, Center } from '@chakra-ui/react';
import sudoku from 'sudoku';

const Sudoku: React.FC = () => {
    const [board, setBoard] = useState<string[]>([]);
    const [solvedBoard, setSolvedBoard] = useState<string[]>([]);

    useEffect(() => {
        // Generate a new Sudoku puzzle on mount
        const newPuzzle = sudoku.makepuzzle();
        const solvedPuzzle = sudoku.solvepuzzle(newPuzzle);

        setBoard(newPuzzle.map((cell: number | null) => (cell !== null ? (cell + 1).toString() : '')));
        setSolvedBoard(solvedPuzzle.map((cell: number | null) => (cell !== null ? (cell + 1).toString() : '')));
    }, []);

    const handleInputChange = (value: string, index: number) => {
        const updatedBoard = [...board];
        updatedBoard[index] = value;
        setBoard(updatedBoard);
    };

    const checkSolution = () => {
        const isSolved = board.every((cell, index) => cell === solvedBoard[index]);
        alert(isSolved ? 'Sudoku solved correctly!' : 'Solution is incorrect.');
    };

    const getBorderStyle = (row: number, col: number) => {
        let style = {};
        if (row % 3 === 0 && row !== 0) style = { ...style, borderTopWidth: '3px' };
        if (col % 3 === 0 && col !== 0) style = { ...style, borderLeftWidth: '3px' };
        return style;
    };

    const renderBoard = () => {
        return board.map((value, index) => {
            const row = Math.floor(index / 9);
            const col = index % 9;

            return (
                <Input
                    key={index}
                    type="number"
                    value={value}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    width="50px"
                    height="50px"
                    textAlign="center"
                    maxLength={1}
                    isReadOnly={solvedBoard[index] !== ''}
                    variant="outline"
                    borderColor="gray.400"
                    fontSize="lg"
                    borderWidth="1px"
                    _focus={{ borderColor: 'blue.400' }}
                    {...getBorderStyle(row, col)} // Apply the thicker border for 3x3 grids
                />
            );
        });
    };

    return (
        <Center>
            <Box>
                <Grid
                    templateColumns="repeat(9, 50px)"
                    gap="1px"
                    border="3px solid black"
                    bg="gray.100"
                    p="2"
                >
                    {renderBoard()}
                </Grid>
                <Button onClick={checkSolution} mt={4} colorScheme="blue">
                    Check Solution
                </Button>
            </Box>
        </Center>
    );
};

export default Sudoku;
