import React from 'react';
import { AuthUser } from "aws-amplify/auth";
import HeadlinesListView from "../../components/Feed/HeadlinesListView";
import NewsTicker from "../../components/Ticker/Ticker";
import {Box} from "@chakra-ui/react";
import HeadlinesCardView from "../../components/Feed/HeadlinesCardView";

interface BusinessProps {
    user?: AuthUser
    signOut?: any
}

const RealEstate = ({user, signOut}: BusinessProps) => (
    <>
        <HeadlinesCardView category="REAL_ESTATE"/>
    </>
);

export default RealEstate;