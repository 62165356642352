import React, { useEffect } from 'react';
import {Box, SimpleGrid} from '@chakra-ui/react';
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {fetchHomeFeedNews} from "../../store/homeFeedNews/homeFeedNewsSlice";
import NewsCard from "../../components/NewsCard/NewsCard";

const Headlines: React.FC = () => {
    const news = useAppSelector((state: RootState) => state.homeFeedNews.data);
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    useEffect(() => {
        dispatch(fetchHomeFeedNews({ locale, page: 0 }));
    }, [dispatch, locale]);

    return (
        <Box mx="auto">
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} marginTop={5}>
                {news.map((item, index) => (
                    <Box key={index}>
                        <NewsCard news={item} noOfLines={2} imageHeight={'300'}/>
                    </Box>
                ))}

            </SimpleGrid>
        </Box>
    );
};

export default Headlines;
