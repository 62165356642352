import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {StockIndexPerformance} from "../../types";
import axios from "axios";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import {RootState} from "../store";
interface StockIndexPerformanceState {
    isLoading: boolean;
    data: StockIndexPerformance[];
    isError: boolean;
}

const initialState: StockIndexPerformanceState = {
    isLoading: false,
    data: [],
    isError: false,
};

export const fetchStockIndexPerformance: AsyncThunk<StockIndexPerformance[], any, any> = createAsyncThunk(
    'business/fetchStockIndexPerformance',
    async ({ locale }: { locale: string; }, { dispatch, getState }): Promise<StockIndexPerformance[]> => {
        try {
            dispatch(startLoading());
            const state = getState() as RootState;
            const accessToken = state.auth.idToken;
            const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/feed/stockPerformance`, {
                headers: headers,
                params: {
                    locale: locale,
                },
            });
            return response.data;
        } finally {
            dispatch(stopLoading());
        }
    }
);

export const stockIndexPerformanceSlice = createSlice({
    name: 'business/fetchStockIndexPerformance',
    initialState: initialState,
    reducers: {
        resetState(state) {
            state.data = [];
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchStockIndexPerformance.pending, (state) => {
                state.isLoading = true;
                state.data = [];
            })
            .addCase(fetchStockIndexPerformance.fulfilled, (state, action: PayloadAction<StockIndexPerformance[]>) => {
                state.isLoading = false;
                state.isError = false;
                state.data = action.payload;
            })
            .addCase(fetchStockIndexPerformance.rejected, (state, action) => {
                console.error('Evt=news/fetchTopNews', action);
                state.isLoading = false;
                state.isError = true;
                state.data = [];
            });
    }
});

export const { resetState } = stockIndexPerformanceSlice.actions;
export default stockIndexPerformanceSlice.reducer;
