import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {
    appleSignIn,
    authenticateUser,
    confirmPassword,
    confirmSignUp,
    facebookSignIn,
    forgotPassword,
    generateVerificationCode,
    getSession,
    googleSignIn,
    signOut,
    signUp,
} from '../../utils/auth';
import {RootState} from "../store";

interface AuthState {
    idToken: string | null;
    loading: boolean;
    error: string | null;
}

const initialState: AuthState = {
    idToken: null,
    loading: false,
    error: null,
};

function extractIdToken(session: CognitoUserSession) {
    return session.getIdToken().getJwtToken();
}

// Refresh ID token
export const refreshIdToken = createAsyncThunk(
    'auth/refreshIdToken',
    async (_, thunkAPI) => {
        try {
            const session = await getSession();
            if (!session) {
                throw new Error("No active session");
            }
            return extractIdToken(session);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const signInUser = createAsyncThunk(
    'auth/signInUser',
    async ({ username, password }: { username: string; password: string }, thunkAPI) => {
        try {
            let session: CognitoUserSession = await authenticateUser(username, password);
            return extractIdToken(session);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const signUpUser = createAsyncThunk(
    'auth/signUpUser',
    async ({ email, password }: { email: string; password: string }, thunkAPI) => {
        try {
            
            await signUp(email, email, password);
            return { username: email, email: email };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const confirmSignUpUser = createAsyncThunk(
    'auth/confirmSignUpUser',
    async ({ username, code }: { username: string; code: string }, thunkAPI) => {
        try {
            await confirmSignUp(username, code);
            return username;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const regenerateVerificationCode = createAsyncThunk(
    "auth/regenerateVerificationCode",
    async ({ email }: { email: string }, { rejectWithValue }) => {
        try {
            return await generateVerificationCode(email);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// Forgot password thunk
export const forgotPasswordThunk = createAsyncThunk(
    'auth/forgotPassword',
    async (username: string, thunkAPI) => {
        try {
            await forgotPassword(username);
            return username;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Confirm password thunk
export const confirmPasswordThunk = createAsyncThunk(
    'auth/confirmPassword',
    async ({ username, code, newPassword }: { username: string; code: string; newPassword: string }, thunkAPI) => {
        try {
            await confirmPassword(username, code, newPassword);
            return username;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Thunks for federated sign-in
export const googleSignInUser = createAsyncThunk('auth/googleSignInUser', async (_, thunkAPI) => {
    try {
        
        await googleSignIn();
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const facebookSignInUser = createAsyncThunk('auth/facebookSignInUser', async (_, thunkAPI) => {
    try {
        await facebookSignIn();
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const appleSignInUser = createAsyncThunk('auth/appleSignInUser', async (_, thunkAPI) => {
    try {
        await appleSignIn();
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const signOutUser = createAsyncThunk('auth/signOutUser', async (_, thunkAPI) => {
    try {
        await signOut();
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Handle regular sign-in
        builder
            // Forgot password
            .addCase(forgotPasswordThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(forgotPasswordThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to reset password';
            })
            // Confirm password
            .addCase(confirmPasswordThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(confirmPasswordThunk.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(confirmPasswordThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to confirm password';
            })
            .addCase(signInUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(signInUser.fulfilled, (state, action: PayloadAction<string>) => {
                state.loading = false;
                state.idToken = action.payload;
            })
            .addCase(signInUser.rejected, (state, action) => {
                state.loading = false;
                // @ts-ignore
                state.error = action.payload.message || 'Failed to sign in';
            })
            .addCase(signUpUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(signUpUser.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(signUpUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to sign up';
            })
            .addCase(confirmSignUpUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(confirmSignUpUser.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(confirmSignUpUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to confirm sign-up';
            })
            // Handle federated sign-ins
            .addCase(googleSignInUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(googleSignInUser.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(googleSignInUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to sign in with Google';
            })
            .addCase(facebookSignInUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(facebookSignInUser.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(facebookSignInUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to sign in with Facebook';
            })
            .addCase(appleSignInUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(appleSignInUser.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(appleSignInUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to sign in with Apple';
            })
            .addCase(refreshIdToken.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(refreshIdToken.fulfilled, (state, action: PayloadAction<string>) => {
                state.loading = false;
                state.idToken = action.payload;
            })
            .addCase(refreshIdToken.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to refresh ID token';
            })
            .addCase(signOutUser.fulfilled, (state) => {
                state.idToken = null;
            })
            .addCase(regenerateVerificationCode.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(regenerateVerificationCode.fulfilled, (state, action) => {
                state.loading = false;
                console.log("verification code sent");
                // Handle the success case, update state if needed
            })
            .addCase(regenerateVerificationCode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to regenerate verification code";
            });
    },
});
export const selectAuthState = (state: RootState) => state.auth;
export default authSlice.reducer;
