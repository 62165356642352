import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    Icon,
    Center,
    Stack,
    useTheme,
    FormControl,
    FormLabel,
    useBreakpointValue
} from "@chakra-ui/react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { forgotPasswordThunk } from "../../store/auth/authSlice"; // Forgot Password Thunk
import { FormattedMessage } from "react-intl";
import useCustomToast from "../../hooks/useCustomToast";

export const ForgotPassword = () => {
    const theme = useTheme();
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showToast = useCustomToast();

    const handleForgotPassword = () => {
        dispatch(forgotPasswordThunk( email))
            .then(() => {
                showToast({
                    title: "Success!",
                    description: "Password reset instructions have been sent to your email.",
                    status: "success",
                    position: "bottom-right"
                });
                navigate("/confirm-password");
            })
            .catch((error) => {
                console.error(error);
                showToast({
                    title: "Error!",
                    description: "Failed to send reset instructions.",
                    status: "error",
                    position: "bottom-right"
                });
            });
    };

    // Responsive values
    const containerWidth = useBreakpointValue({ base: "90%", md: "75%", lg: "50%" });
    const boxPadding = useBreakpointValue({ base: "10px", md: "20px" });
    const buttonSize = useBreakpointValue({ base: "full", md: "md" });

    return (
        <Box width={containerWidth} margin="0 auto" padding="20px">
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Flex alignItems="center" cursor="pointer" onClick={() => navigate("/login")}>
                    <Icon as={BsArrowLeft} marginRight="8px" />
                    <Text color={theme.colors.gray} fontSize="15px" _hover={{ textDecoration: "underline", cursor: "pointer" }}>
                        <FormattedMessage id="backToLogin" defaultMessage="Back to Login" />
                    </Text>
                </Flex>
            </Flex>
            <Box
                width="100%"
                margin="0 auto"
                border={`3px solid ${theme.colors.gray[300]}`}
                borderRadius="20px"
                padding={boxPadding}
                maxWidth="500px"
            >
                <Text fontSize="24px" textAlign="center" mb={6}>
                    <FormattedMessage id="resetPassword" defaultMessage="Reset Password" />
                </Text>
                <Center marginBottom={6}>
                    <Stack spacing={4} align={'center'} w={'full'}>
                        <FormControl id="email">
                            <FormLabel>
                                <FormattedMessage id="emailAddress" defaultMessage="Email Address" />
                            </FormLabel>
                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </FormControl>
                        <Button
                            w={buttonSize}
                            bg={'blue.400'}
                            color={'white'}
                            onClick={handleForgotPassword}
                            _hover={{
                                bg: 'blue.500',
                            }}
                        >
                            <FormattedMessage id="sendResetLink" defaultMessage="Send Reset Link" />
                        </Button>
                    </Stack>
                </Center>
            </Box>
        </Box>
    );
};
