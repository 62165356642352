import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        loadingCount: 0,
        isLoading: false,
    },
    reducers: {
        startLoading(state) {
            state.loadingCount += 1;
            state.isLoading = true;
        },
        stopLoading(state) {
            if (state.loadingCount > 0) {
                state.loadingCount -= 1;  // Decrement the loading count
            }
            if (state.loadingCount === 0) {
                state.isLoading = false;
            }
        },
    },
});

export const { startLoading, stopLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
