import {Stack} from "@chakra-ui/react";
import {
        FaEnvelope,
        FaFacebookF,
        FaInstagram,
        FaLinkedinIn,
        FaTwitter,
        FaYoutube
} from "react-icons/fa";
import React from "react";
import SocialButton from "./SocialButton";

const SocialLinks = () => (
    <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Twitter'} href={'#'} hoverColor="blue.500">
                    <FaTwitter />
            </SocialButton>
            <SocialButton label={'YouTube'} href={'#'} hoverColor="red.500">
                    <FaYoutube />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'#'} hoverColor="pink.500">
                    <FaInstagram />
            </SocialButton>
            <SocialButton label={'Mail'} href={'#'} hoverColor="yellow.500">
                    <FaEnvelope />
            </SocialButton>
            <SocialButton label={'LinkedIn'} href={'#'} hoverColor="blue.800">
                    <FaLinkedinIn />
            </SocialButton>
            <SocialButton label={'LinkedIn'} href={'#'} hoverColor="blue.400">
                    <FaFacebookF />
            </SocialButton>
    </Stack>
)

export default SocialLinks;
