import {Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import React from "react";
import Sudoku from "./Suduko";
import Crossword from "./Crossword";

const DailyQuest = () => (
            <Tabs isFitted variant="enclosed">
                <TabList mb="1em">
                    <Tab>Sudoku</Tab>
                    <Tab>Crossword</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Sudoku />
                    </TabPanel>
                    <TabPanel>
                        <Crossword />
                    </TabPanel>
                </TabPanels>
            </Tabs>
);

export default DailyQuest;