import React from 'react';
import { Link } from 'react-router-dom';

import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
    Text,
    Box
} from '@chakra-ui/react';

const AboutUsDrawer: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
    return (
        <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="full">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>About Us</DrawerHeader>
                <DrawerBody>
                    <Box>
                        <Text fontSize="lg" mb={4}>
                            {/* Replace with your actual About Us content */}
                            Welcome to NiSee.com. We provide the latest news on various topics to keep you informed.
                        </Text>
                        <Text fontSize="md">
                            {/* More information can go here */}
                            Our team is dedicated to bringing you top stories and engaging content from around the world.
                        </Text>
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default AboutUsDrawer;
