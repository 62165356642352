import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { News } from "../../types";
import axios from "axios";
import { startLoading, stopLoading } from "../loading/loadingSlice";
import { RootState } from "../store";
import { selectCurrentRegion } from "../region/regionSlice";

interface CategoryNewsFeedState {
    isLoading: boolean;
    data: { [category: string]: News[] };
    isError: boolean;
    currentPage: { [category: string]: number };
    hasMore: { [category: string]: boolean };
}

const initialState: CategoryNewsFeedState = {
    isLoading: false,
    data: {},
    isError: false,
    currentPage: {},
    hasMore: {},
};

export const fetchCategoryFeedNews: AsyncThunk<{ category: string, news: News[] }, { category: string; locale: string; page: number }, any> = createAsyncThunk(
    'news/fetchCategoryFeedNews',
    async ({ category, locale, page }, { dispatch, getState }): Promise<{ category: string, news: News[] }> => {
        try {
            dispatch(startLoading());
            const state = getState() as RootState;
            const accessToken = state.auth.idToken;
            const headers = accessToken ? { Authorization: `Bearer ${accessToken}`, 'Accept-Language': locale } : {};
            const currentRegion = selectCurrentRegion(state)?.code || 'ALL';
            // API call based on category and region
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/feedByCategory/${category}/${currentRegion}`, {
                headers: headers,
                params: {
                    page: page,
                    size: 100
                },
            });

            if (response.data.last) {
                dispatch(categoryNewsFeedSlice.actions.setHasMore({ category, hasMore: false }));
            }

            return { category, news: response.data.content };
        } finally {
            dispatch(stopLoading());
        }
    }
);

export const categoryNewsFeedSlice = createSlice({
    name: 'news/categoryNewsFeed',
    initialState: initialState,
    reducers: {
        setHasMore(state, action: PayloadAction<{ category: string; hasMore: boolean }>) {
            state.hasMore[action.payload.category] = action.payload.hasMore;
        },
        incrementPage(state, action: PayloadAction<{ category: string }>) {
            if (state.currentPage[action.payload.category] === undefined) {
                state.currentPage[action.payload.category] = 1;
            } else {
                state.currentPage[action.payload.category] += 1;
            }
        },
        resetNewsState(state) {
            state.data = {};
            state.currentPage = {};
            state.hasMore = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoryFeedNews.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCategoryFeedNews.fulfilled, (state, action: PayloadAction<{ category: string, news: News[] }>) => {
                state.isLoading = false;

                const { category, news } = action.payload;

                const uniqueNews = news.filter(
                    (newsItem) => !state.data[category]?.some(existingItem => existingItem.id === newsItem.id)
                );

                if (!state.data[category]) {
                    state.data[category] = [];
                }

                state.data[category] = [...state.data[category], ...uniqueNews];
            })
            .addCase(fetchCategoryFeedNews.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { incrementPage, resetNewsState } = categoryNewsFeedSlice.actions;
export default categoryNewsFeedSlice.reducer;
