// Chakra imports
import {Box, Flex, useColorModeValue} from "@chakra-ui/react";
import React, {useEffect} from "react";
import Header from "./components/Header";
import Sidenav from "./components/Sidenav";
import BasicInfo from "./components/BasicInfo";
import PlatformSettings from "./components/PlatformSettings";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {fetchUserDetails} from "../../store/user/userSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";

function Profile() {
    const dispatch = useAppDispatch();
    useColorModeValue("gray.700", "white");
    useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

     const { user } = useAppSelector((state) => state.user);
     const locale = useAppSelector((state: RootState) => state.locale.locale);

    useEffect(() => {
        dispatch(fetchUserDetails(locale)); // Pass the locale as needed
    }, [dispatch]);

    return (
        <Flex justifyContent="space-between" gap="2%" margin="auto" width="100%">
          <Box width="100%">
              <Flex justifyContent="space-between">
                  <Box width="20%" >
                      <Sidenav/>
                  </Box>
                  <Box width="80%" marginLeft={5}>
                      {user ? (
                          <>
                              <Box>
                                  <Header user={user} />
                              </Box>
                              <Box marginTop={5}>
                                  <BasicInfo user={user} />
                              </Box>
                              <Box marginTop={5}>
                                  <PlatformSettings user={user} />
                              </Box>
                          </>
                      ) : (
                          <Box textAlign="center" p={5}>
                                  User is not logged in
                          </Box>
                      )}
                  </Box>
              </Flex>
              <>
              </>
          </Box>

      </Flex>
  );
}

export default Profile;
