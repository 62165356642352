import {Box, Flex, Text, useBreakpointValue, useColorModeValue} from '@chakra-ui/react';
import {FC} from 'react';
import {News} from "../../types";
import {timeAgo} from "../../utils/dateUtils";
import FallbackImage from "../../components/FallbackImage";

type NewsCardProps = {
    news: News,
    noOfLines: number,
    imageHeight: string,
    fontWeight?: string,
};

const NewsCard: FC<NewsCardProps> = ({news, noOfLines, imageHeight, fontWeight = "semibold"}) => {
    const isMobile = useBreakpointValue({ base: true, md: false }); // true for mobile screens, false for md and up


    if (!news) {
        return <Text>Loading</Text>;
    }
    return (
        <Box alignItems="center" justifyContent="center" w="full">
            <Box rounded="md" shadow="none">
                {isMobile ? (
                    // Mobile layout: Image on the left, text on the right
                    <Flex align="center" height={"auto"}>
                        <FallbackImage
                            src={news.imageUrl}
                            alt={`Picture of ${news.title}`}
                            borderRadius="md"
                            objectFit="cover"
                            w="30%"   // Image width for mobile
                            h={"auto"}
                            flatBottom={false}
                        />
                        <Box marginLeft={4} flex="1">
                            <Text
                                fontSize="md"
                                fontWeight={fontWeight}
                                as="h4"
                                lineHeight="tight"
                                noOfLines={noOfLines}
                                textAlign="left"
                                _hover={{ textDecoration: "underline" }}
                            >
                                {news.title}
                            </Text>
                        </Box>
                    </Flex>
                ) : (
                    // Default layout for larger screens
                    <>
                        <FallbackImage
                            src={news.imageUrl}
                            alt={`Picture of ${news.title}`}
                            w="full"
                            borderRadius="md"
                            objectFit="cover"
                            h={imageHeight}
                            flatBottom={false}
                        />
                        <Box marginTop={2}>
                            <Flex mt="1" justifyContent="space-between" alignContent="center">
                                <Text
                                    fontSize="lg"
                                    fontWeight={fontWeight}
                                    as="h4"
                                    lineHeight="tight"
                                    noOfLines={noOfLines}
                                    textAlign="left"
                                    _hover={{ textDecoration: "underline" }}
                                >
                                    {news.title}
                                </Text>
                            </Flex>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default NewsCard;
