import React from 'react';
import { AuthUser } from "aws-amplify/auth";
import HeadlinesCardView from "../../components/Feed/HeadlinesCardView";

interface BusinessProps {
    user?: AuthUser
    signOut?: any
}

const Health = ({user, signOut}: BusinessProps) => (
            <>
                <HeadlinesCardView category="HEALTH"/>
            </>
);

export default Health;