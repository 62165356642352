import React, { useEffect } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import useCustomToast from "../../hooks/useCustomToast";

const Notifications: React.FC = () => {
    const showToast = useCustomToast();

    useEffect(() => {
        const socket = new SockJS(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}websocket`);
        const stompClient = new Client({
            webSocketFactory: () => socket,
            reconnectDelay: 5000,
            onConnect: (frame) => {
                console.log('Connected: ' + frame);

                // Subscribe to /topic/notifications
                stompClient.subscribe('/topic/notifications', (message) => {
                    if (message.body) {
                        // Show toast notification
                        showToast({
                            title: "New Notification!",
                            description: message.body,
                            status: "info",
                            variant: "subtle",
                            position: "top-right",
                        });
                    }
                });
            },
            onStompError: (error) => {
                console.error('STOMP error:', error);
            }
        });

        stompClient.activate();

        // Clean up the WebSocket connection on component unmount
        return () => {
            if (stompClient) {
                stompClient.deactivate();
            }
        };
    }, [showToast]); // Added showToast to the dependency array

    return null; // No UI needed
};

export default Notifications;
