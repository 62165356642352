import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Text,
    Input,
    Button,
    FormControl,
    FormLabel,
    Stack,
    useTheme,
    useBreakpointValue,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { confirmPasswordThunk } from "../../store/auth/authSlice"; // Redux action for password reset
import { FormattedMessage } from "react-intl";
import useCustomToast from "../../hooks/useCustomToast";

export const ResetPassword = () => {
    const theme = useTheme();
    const [username, setUsername] = useState("");
    const [code, setResetCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showToast = useCustomToast();

    const handleResetPassword = () => {
        if (newPassword !== confirmPassword) {
            showToast({
                title: "Error!",
                description: "Passwords do not match.",
                status: "error",
                position: "bottom-right",
            });
            return;
        }

        dispatch(confirmPasswordThunk({ username, code, newPassword }))
            .then(() => {
                showToast({
                    title: "Success!",
                    description: "Password reset successfully. You can now log in with your new password.",
                    status: "success",
                    position: "bottom-right",
                });
                navigate("/login");
            })
            .catch((error) => {
                console.error(error);
                showToast({
                    title: "Error!",
                    description: "Failed to reset the password.",
                    status: "error",
                    position: "bottom-right",
                });
            });
    };

    // Responsive values
    const containerWidth = useBreakpointValue({ base: "90%", md: "75%", lg: "50%" });
    const boxPadding = useBreakpointValue({ base: "10px", md: "20px" });
    const buttonSize = useBreakpointValue({ base: "full", md: "md" });

    return (
        <Box width={containerWidth} margin="0 auto" padding="20px">
            <Box
                width="100%"
                margin="0 auto"
                border={`3px solid ${theme.colors.gray[300]}`}
                borderRadius="20px"
                padding={boxPadding}
                maxWidth="500px"
            >
                <Text fontSize="24px" textAlign="center" mb={6}>
                    <FormattedMessage id="resetPassword" defaultMessage="Reset Password" />
                </Text>
                <Stack spacing={4}>
                    <FormControl id="email">
                        <FormLabel>
                            <FormattedMessage id="emailAddress" defaultMessage="Email Address" />
                        </FormLabel>
                        <Input type="email" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </FormControl>
                    <FormControl id="resetCode">
                        <FormLabel>
                            <FormattedMessage id="resetCode" defaultMessage="Reset Code" />
                        </FormLabel>
                        <Input type="text" value={code} onChange={(e) => setResetCode(e.target.value)} />
                    </FormControl>
                    <FormControl id="newPassword">
                        <FormLabel>
                            <FormattedMessage id="newPassword" defaultMessage="New Password" />
                        </FormLabel>
                        <Input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </FormControl>
                    <FormControl id="confirmPassword">
                        <FormLabel>
                            <FormattedMessage id="confirmPassword" defaultMessage="Confirm New Password" />
                        </FormLabel>
                        <Input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </FormControl>
                    <Button
                        w={buttonSize}
                        bg={'blue.400'}
                        color={'white'}
                        onClick={handleResetPassword}
                        _hover={{
                            bg: 'blue.500',
                        }}
                    >
                        <FormattedMessage id="resetPassword" defaultMessage="Reset Password" />
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};
