export const getCurrentDate = (locale: Intl.LocalesArgument) => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date().toLocaleDateString(locale, options);
};

export const getMonthNumberFromName = (monthName: string) => new Date(Date.parse(monthName + " 1, 2000")).getMonth() + 1;

export const getMonthName = (monthNumber: number, locale: Intl.LocalesArgument) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString(locale, { month: "long" });
};

export const timeAgo = (dateString?: string): string => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    const now = new Date();
    const secondsAgo = Math.floor((now.getTime() - date.getTime()) / 1000); // Ensure `getTime()` is used to get the timestamp

    let unit: Intl.RelativeTimeFormatUnit = 'second';
    let value: number = secondsAgo;

    if (secondsAgo >= 60) {
        const minutesAgo = Math.floor(secondsAgo / 60);
        if (minutesAgo >= 60) {
            const hoursAgo = Math.floor(minutesAgo / 60);
            if (hoursAgo >= 24) {
                const daysAgo = Math.floor(hoursAgo / 24);
                if (daysAgo >= 7) {
                    const weeksAgo = Math.floor(daysAgo / 7);
                    unit = 'week';
                    value = weeksAgo;
                } else {
                    unit = 'day';
                    value = daysAgo;
                }
            } else {
                unit = 'hour';
                value = hoursAgo;
            }
        } else {
            unit = 'minute';
            value = minutesAgo;
        }
    }

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    return rtf.format(-value, unit);
}
