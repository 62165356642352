import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {News} from "../../types";
import axios from "axios";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import {RootState} from "../store";
interface TopNewsState {
    isLoading: boolean;
    data: News[];
    isError: boolean;
    currentPage: number;
    hasMore: boolean;
}

const initialState: TopNewsState = {
    isLoading: false,
    data: [],
    isError: false,
    currentPage: 0, // Start from the first page
    hasMore: true,  // Assume there are more pages to fetch initially
};

export const fetchTopNews: AsyncThunk<News[], any, any> = createAsyncThunk(
    'news/fetchTopNewsAsync',
    async ({ locale, page }: { locale: string; page: number }, { dispatch, getState }): Promise<News[]> => {
        try {
            dispatch(startLoading());
            const state = getState() as RootState;
            const accessToken = state.auth.idToken;
            const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/feed/top-stories`, {
                headers: headers,
                params: {
                    locale: locale,
                    page: page,
                },
            });

            // If the last page is reached, set `hasMore` to false
            if (response.data.last) {
                dispatch(topNewsSlice.actions.setHasMore(false));
            }

            return response.data.content; // Assuming content holds the news array
        } finally {
            dispatch(stopLoading());
        }
    }
);

export const topNewsSlice = createSlice({
    name: 'news/fetchTopNews',
    initialState: initialState,
    reducers: {
        setHasMore(state, action: PayloadAction<boolean>) {
            state.hasMore = action.payload;
        },
        incrementPage(state) {
            state.currentPage += 1;
        },
        resetNewsState(state) {
            state.data = [];
            state.currentPage = 1;
            state.hasMore = true;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTopNews.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchTopNews.fulfilled, (state, action: PayloadAction<News[]>) => {
                state.isLoading = false;

                // Filter out duplicates based on unique news ID
                const uniqueNews = action.payload.filter(
                    (newsItem) => !state.data.some(existingItem => existingItem.id === newsItem.id)
                );

                state.data = [...state.data, ...uniqueNews]; // Append only unique data
            })
            .addCase(fetchTopNews.rejected, (state, action) => {
                console.error('Evt=news/fetchTopNews', action);
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { incrementPage, resetNewsState } = topNewsSlice.actions;
export default topNewsSlice.reducer;
