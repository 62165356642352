import React from 'react';
import { Icon } from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";

interface HamburgerMenuProps {
    onClick: () => void;
}

export const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ onClick }) => {
    return (
        <Icon
            as={FaBars}
            onClick={onClick}
            color="rgb(90, 90, 90)"
            fontSize="20px"
            cursor="pointer"
            display={["block", "block", "none"]}
        />
    );
};
