import * as React from "react"
import {
  chakra,
  ImageProps,
  forwardRef,
  useColorMode
} from "@chakra-ui/react"
import logoColor from "../svg/logo-no-background.svg"
import logoWhite from "../svg/logo-white.svg"

export const Logo = forwardRef<ImageProps, "img">((props, ref) => {
  const { colorMode } = useColorMode();
  const logoSrc = colorMode === "dark" ? logoWhite : logoColor;

  return <chakra.img src={logoSrc} alt="Logo" ref={ref} {...props} />
})
