import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { AiFillFacebook, AiFillApple } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    Icon,
    Center,
    Stack,
    useTheme,
    FormControl,
    FormLabel,
    useBreakpointValue,
    FormErrorMessage,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { signUpUser } from "../../store/auth/authSlice"; // Import the thunk
import useCustomToast from "../../hooks/useCustomToast";
import { selectAuthState } from "../../store/auth/authSlice";
import { useAppSelector } from "../../hooks/useAppSelector";

export const SignUp = () => {
    const theme = useTheme();
    const accentColor = theme.colors.gray;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const showToast = useCustomToast();
    const dispatch = useAppDispatch();
    const authState = useAppSelector(selectAuthState);

    const handleSignUpClick = () => {
        if (!email || !password || !confirmPassword) {
            setError("All fields are required.");
            return;
        }
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        if (password.length < 6) {
            setError("Password must be at least 6 characters.");
            return;
        }

        setError("");

        dispatch(signUpUser({ email, password }))
            .unwrap()
            .then(() => {
                showToast({
                    title: "Success!",
                    description: "Your account has been created.",
                    status: "success",
                    position: "bottom-right",
                });
                navigate("/verify", { state: { email } });
            })
            .catch((error: { message: any; }) => {
                showToast({
                    title: "Error",
                    description: error.message || "Sign up failed.",
                    status: "error",
                    position: "bottom-right",
                });
            });
    };

    const containerWidth = useBreakpointValue({ base: "90%", md: "75%", lg: "50%" });
    const boxPadding = useBreakpointValue({ base: "10px", md: "20px" });
    const formSpacing = useBreakpointValue({ base: 4, md: 6 });
    const buttonSize = useBreakpointValue({ base: "full", md: "md" });

    return (
        <Box width={containerWidth} margin="0 auto" padding="20px">
            <Box
                width="100%"
                margin="0 auto"
                border={`3px solid ${theme.colors.gray[300]}`}
                borderRadius="20px"
                padding={boxPadding}
                maxWidth="500px"
            >
                <Text fontSize="24px" textAlign="center" mb={6}>Sign Up</Text>
                <Center marginBottom={6}>
                    <Stack spacing={2} align={'center'} w={'full'}>
                        <Button w={buttonSize} variant={'outline'} leftIcon={<FcGoogle />} onClick={() => {/* handle Google sign up */}}>
                            Sign up with Google
                        </Button>
                        <Button w={buttonSize} variant={'outline'} leftIcon={<AiFillFacebook />} onClick={() => {/* handle Facebook sign up */}}>
                            Sign up with Facebook
                        </Button>
                        <Button w={buttonSize} variant={'outline'} leftIcon={<AiFillApple />} onClick={() => {/* handle Apple sign up */}}>
                            Sign up with Apple
                        </Button>
                    </Stack>
                </Center>
                <Box mt={8}>
                    <Stack spacing={formSpacing}>
                        <FormControl id="email" isRequired isInvalid={!!error}>
                            <FormLabel>Email Address</FormLabel>
                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </FormControl>
                        <FormControl id="password" isRequired isInvalid={!!error}>
                            <FormLabel>Password</FormLabel>
                            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </FormControl>
                        <FormControl id="confirm-password" isRequired isInvalid={!!error}>
                            <FormLabel>Confirm Password</FormLabel>
                            <Input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            <FormErrorMessage>{error}</FormErrorMessage>
                        </FormControl>
                        <Stack spacing={10}>
                            <Button
                                bg={'blue.400'}
                                color={'white'}
                                onClick={handleSignUpClick}
                                isLoading={authState.loading}
                                _hover={{ bg: 'blue.500' }}>
                                Sign Up
                            </Button>
                        </Stack>
                    </Stack>
                    <Text color="#6c798f" textAlign="center" fontSize="15px" mt={6}>
                        Already have an account?
                    </Text>
                    <Text
                        color="rgb(69, 112, 216)"
                        fontWeight="700"
                        fontSize="15px"
                        textAlign="center"
                        cursor="pointer"
                        mb={6}
                        onClick={() => navigate("/login")}
                    >
                        Login here
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};
