import { AuthSignInWithRedirectInput } from '@aws-amplify/auth/dist/esm/types';
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import {signInWithRedirect} from "aws-amplify/auth";

const poolData = {
    UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID || 'ap-south-1_yBksDduvb',
    ClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || '498ubkoj0hkv6o9al0mlek5lr1',
};

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

export const authenticateUser = (Username: string, Password: string): Promise<CognitoUserSession> => {
    
    const authenticationDetails: AuthenticationDetails = new AuthenticationDetails({
        Username,
        Password,
    });

    const userData = {
        Username: Username,
        Pool: userPool,
    };

    const cognitoUser: CognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result: CognitoUserSession) => {
                
                const idToken: string = result.getIdToken().getJwtToken();
                localStorage.setItem('jwtToken', idToken); // Save JWT token to local storage
                resolve(result);
            },
            onFailure: (err) => {
                
                reject(err);
            },
        });
    });
};

export function signUp(username: string, email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
        userPool.signUp(
            username,
            password,
            [new CognitoUserAttribute({Name: 'email', Value: email,})],
            [],
            (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            }
        );
    });
}


export function confirmSignUp(username: string, code: string): Promise<any> {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
}

export function generateVerificationCode(username: string): Promise<any> {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
}


export function signInFederated(input: AuthSignInWithRedirectInput): Promise<void> {
    return signInWithRedirect(input)
}

export function googleSignIn(): Promise<void> {
    
    return signInFederated({ provider: "Google" });
}

export function facebookSignIn() {
    return signInFederated({ provider: "Facebook" });
}

export function appleSignIn() {
    return signInFederated({ provider: "Apple" });
}

export function forgotPassword(username: string): Promise<any> {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: (data) => {
                resolve(data);
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
}


export function confirmPassword(username: string, code: string, newPassword: string): Promise<any> {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: () => {
                resolve('Password reset successfully');
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
}


export function signOut(): Promise<void> {
    const cognitoUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
        if (cognitoUser) {
            cognitoUser.signOut(() => {
                localStorage.removeItem('jwtToken'); // Optionally clear JWT token from local storage
                resolve();
            });
        } else {
            reject('No user is currently signed in');
        }
    });
}


export function getSession(): Promise<CognitoUserSession | null> {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
        return Promise.resolve(null); // No user is signed in
    }

    return new Promise((resolve, reject) => {
        cognitoUser.getSession((error: Error | null, session: CognitoUserSession | null) => {
            if (error) {
                reject(error); // Error occurred, reject promise
            } else if (session) {
                // Check if the access token is still valid
                if (!session.isValid()) {
                    // Access token is expired, so refresh it
                    cognitoUser.refreshSession(session.getRefreshToken(), (refreshError, refreshedSession) => {
                        console.info("Going to refresh the session");
                        if (refreshError) {
                            console.error("Refresh error", refreshError);
                            reject(refreshError); // Failed to refresh token
                        } else {
                            const newIdToken: string = refreshedSession.getIdToken().getJwtToken();
                            localStorage.setItem('jwtToken', newIdToken); // Update JWT token in local storage
                            console.info("Session refreshed successfully");
                            resolve(refreshedSession); // Return the refreshed session
                        }
                    });
                } else {
                    resolve(session); // Session is valid, resolve promise
                }
            } else {
                resolve(null); // Handle any unexpected case where session is null
            }
        });
    });
}

