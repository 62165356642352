import {ResourcesConfig} from "aws-amplify";

/*** https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-social-idp.html ***/
const awsExports: ResourcesConfig = {
    Auth: {
        Cognito: {
            //  Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID || 'ap-south-1_yBksDduvb',
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || '498ubkoj0hkv6o9al0mlek5lr1',
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOLS_ID || 'ap-south-1:edf72ffc-7feb-451a-85ea-6b6393432cb2',
            // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
            allowGuestAccess: false,
            // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
            // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
            signUpVerificationMethod: 'link', // 'code' | 'link'
            loginWith: {
                // OPTIONAL - Hosted UI configuration
                oauth: {
                    domain: process.env.REACT_APP_COGNITO_DOMAIN || 'nisee-test.auth.ap-south-1.amazoncognito.com',
                    scopes: ['email', 'openid'],
                    // scopes: [
                    //     'phone',
                    //     'email',
                    //     'profile',
                    //     'openid',
                    //     'aws.cognito.signin.user.admin'
                    // ],
                    redirectSignIn: [ process.env.REACT_APP_SIGN_IN_REDIRECT || 'http://localhost:3000/'],
                    redirectSignOut: [ process.env.REACT_APP_SIGN_OUT_REDIRECT || 'http://localhost:3000/'],
                    responseType: 'code' // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
                }
            }
        }
    }
};
export default awsExports;