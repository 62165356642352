import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Category} from "../../types";
import axios from "axios";
import {startLoading, stopLoading} from "../loading/loadingSlice";

interface CategoryState {
    isLoading: boolean;
    categories: Category[];
    isError: boolean
}

const initialState: CategoryState = {
    isLoading: false,
    categories: [],
    isError: false
}

export const fetchCategories: AsyncThunk<Category[], any, any> = createAsyncThunk(
    'category/fetchCategories',
    async (locale: string, { dispatch }): Promise<Category[]> => {
        try {
            dispatch(startLoading());
            const headers = 'token' ? { Authorization: 'token' } : {};
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/categories`, {
                headers: headers,
                params: {
                    locale: locale,
                },
            });
            return response.data;
        } finally {
            dispatch(stopLoading());
        }
    }
);

export const categorySlice = createSlice({
    name: 'category',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCategories.fulfilled, (state, action: PayloadAction<Category[]>) => {
                state.isLoading = false;
                state.categories = action.payload;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                console.error('Evt=category/fetchCategories', action);
                state.isLoading = false;
                state.isError = true;
            });
    }
});


export default categorySlice.reducer;