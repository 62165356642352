import React from 'react';
import { Select } from "@chakra-ui/react";

import { supportedLanguages } from "../../locales";

interface LanguageSelectorProps {
    locale: string;
    onLanguageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const LanguageSelector: React.FC<LanguageSelectorProps>  = ({ locale, onLanguageChange }) => {

    return (
        <Select value={locale} onChange={onLanguageChange}>
            {supportedLanguages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                    {lang.name}
                </option>
            ))}
        </Select>
    );
};
