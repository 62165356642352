import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import * as CSS from "csstype";
import fallbackImage from '../assets/img/nisee-high-resolution-logo.png';

interface FallbackImageProps {
    src: string;
    alt: string;
    title?: string;
    objectFit?: CSS.Property.ObjectFit;
    h?: string;
    w?: string;
    width?: string;
    borderRadius?: string;
    fallbackSrc?: string;
    boxProps?: any;
    flatBottom?: boolean;
}

const FallbackImage = ({
                           src,
                           alt,
                           title = '',
                           objectFit = 'cover',
                           h = '400px',
                           w = '100%',
                           width = "100%",
                           borderRadius = 'md',
                           fallbackSrc = fallbackImage,
                           boxProps = {},
                           flatBottom = true,
                       }: FallbackImageProps) => {

    return (
        <>
                <Box
                    position="relative"
                    width={w}
                    paddingTop={h === 'auto' ? '0' : '56.34%'}
                    overflow="hidden"
                    {...boxProps}
                >
                    <Image
                        src={src}
                        fallbackSrc={fallbackSrc}
                        alt={alt}
                        position={h === 'auto' ? 'static' : 'absolute'}
                        top={0}
                        left={0}
                        width={width}
                        height={h}
                        objectFit={objectFit}
                        roundedTop={borderRadius}
                        borderRadius={borderRadius}
                        borderBottomRadius={flatBottom ? '0' : borderRadius}
                    />
                    {/* Overlay on hover */}
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        bg="rgba(0, 0, 0, 0.5)" // Semi-transparent grey overlay
                        opacity={0} // Hidden by default
                        _hover={{ opacity: 1 }} // Show overlay on hover
                        transition="opacity 0.5s ease-in-out" // Smooth fade-in transition
                        borderRadius={borderRadius}
                    />
                </Box>
        </>
    );
};

export default FallbackImage;
