import { useToast, UseToastOptions } from "@chakra-ui/react";

/***
 * Show a custom toast notification.
 * https://v2.chakra-ui.com/docs/components/toast/usage
 ***/

type CustomToastOptions = Omit<UseToastOptions, "status"> & {
    status?: "success" | "error" | "warning" | "info";
};

const useCustomToast = () => {
    const toast = useToast();

    const showToast = ({
                           title = "Notification",
                           description = "",
                           status = "info",
                           duration = 5000,
                           isClosable = true,
                            variant = "solid",
                           position = "top-right",
                       }: CustomToastOptions) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable,
            position,
            variant,
        });
    };

    return showToast;
};

export default useCustomToast;
