import {
  Button, Card, CardBody, CardHeader,
  Flex,
  Grid,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {FC, useEffect} from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import ProjectCard from "./ProjectCard";

// Assets
import avatar2 from "../../../assets/img/avatars/avatar2.png";
import avatar4 from "../../../assets/img/avatars/avatar4.png";
import avatar6 from "../../../assets/img/avatars/avatar6.png";
import imageArchitect1 from "../../../assets/img/ImageArchitect1.png";
import imageArchitect2 from "../../../assets/img/ImageArchitect2.png";
import imageArchitect3 from "../../../assets/img/ImageArchitect3.png";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {fetchUserDetails} from "../../../store/user/userSlice";
import {RootState} from "../../../store/store";
import {fetchPostsOfCurrentUser} from "../../../store/userPosts/userPostSlice";
import {News} from "../../../types";
import NewsItemSmall from "../../Home/NewsItemSmall";

interface ProjectsProps {
  title: string;
  description: string;
}

const Projects: FC<ProjectsProps> = ({ title, description }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { posts } = useAppSelector((state) => state.userPosts);

  useEffect(() => {
    dispatch(fetchPostsOfCurrentUser());
  }, [dispatch]);



  const handleClick = () => {
    navigate("/create-article");
  };

  return (
      <>
        <Card p='16px' my='24px' bgColor={bgColor}>
          <CardHeader p='12px 5px' mb='12px'>
            <Flex direction='column'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                {title}
              </Text>
              <Text fontSize='sm' color='gray.500' fontWeight='400'>
                {description}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody px='5px'>
            <Grid
                templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
                gap='24px'>
              <Button
                  p='0px'
                  bg='transparent'
                  color='gray.500'
                  border='1px solid lightgray'
                  onClick={handleClick}
                  borderRadius='15px'
                  minHeight={{ sm: "200px", md: "100%" }}>
                <Flex direction='column' justifyContent='center' align='center'>
                  <Icon as={FaPlus} fontSize='lg' mb='12px' />
                  <Text fontSize='lg' fontWeight='bold'>
                    Create a New Article
                  </Text>
                </Flex>
              </Button>
              {posts.map((post: News) =>
             (   <ProjectCard
                    image={post.imageUrl}
                    name={"Project #3"}
                    category={post.title}
                    description={
                      post.description
                    }
                    avatars={[avatar2, avatar4, avatar6]}
                />)
              )}
            </Grid>
          </CardBody>
        </Card>
      </>
  );
};

export default Projects;
