import React, { useEffect, FC } from 'react';
import {Box, Icon} from '@chakra-ui/react';
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { RootState } from "../store/store";
import {fetchRegions, setRegion} from "../store/region/regionSlice";
import { Select, chakraComponents } from "chakra-react-select";
import { WorldRegion } from "../types";
import {fetchCategoryFeedNews, resetNewsState} from "../store/categoryNewsFeed/categoryNewsFeedSlice";

interface WorldRegionPickerProps {
    onRegionChange: (worldRegion: WorldRegion) => void;
}

const RegionPicker: FC<WorldRegionPickerProps> = ({ onRegionChange }) => {
    const dispatch = useAppDispatch();
    const regions = useAppSelector((state: RootState) => state.region.regions);
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    const storedRegionCode = useAppSelector(
        (state: RootState) => state.region.currentRegion?.code
    );
    const countryOptions = regions.map(region => ({
        value: region.code,
        label: region.name,
    }));

    useEffect(() => {
        if (locale && regions.length === 0) {
            dispatch(fetchRegions(locale));
        }
    }, [dispatch, locale]);

    const defaultCountry = countryOptions.find(
        (option) => option.value === storedRegionCode
    ) || null;

    const handleChange = (selectedOption: any) => {
        const region = regions.find(
            (region) => region.code === selectedOption?.value
        ) || null;
        if (region !== null) {
            onRegionChange(region);
            dispatch(setRegion(region));
            dispatch(resetNewsState());
            dispatch(fetchCategoryFeedNews({ category: 'BUSINESS', locale: 'en', page: 0 }));
            dispatch(fetchCategoryFeedNews({ category: 'TECHNOLOGY', locale: 'en', page: 0 }));
            dispatch(fetchCategoryFeedNews({ category: 'POLITICS', locale: 'en', page: 0 }));
        }
    };

    const customComponents = {
        Option: (props: any) => (
            <chakraComponents.Option {...props}>
                <Box fontWeight="bold" fontSize="15px">
                    {props.children}
                </Box>
            </chakraComponents.Option>
        ),
        SingleValue: (props: any) => (
            <chakraComponents.SingleValue {...props}>
                <Box fontWeight="bold" fontSize="15px">
                    {props.children}
                </Box>
            </chakraComponents.SingleValue>
        ),
    };

    return (
        <Box width={200}>
            <Select
                name="region"
                colorScheme="purple"
                options={countryOptions}
                placeholder="Region"
                variant={"unstyled"}
                size="sm"
                isSearchable={false}
                defaultValue={defaultCountry}
                selectedOptionStyle="check"
                chakraStyles={{
                    container: (base) => ({
                        ...base,
                        width: "100%",
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        paddingX: 4
                    }),
                    control: (base) => ({
                        ...base,
                        borderBottomRadius: "md", // Rounded corners only for bottom
                        borderTopRadius: 0, // No rounded corners
                    }),
                    menu: (base) => ({
                        ...base,
                        borderRadius: 0, // No rounded corners for dropdown
                    }),
                    option: (base) => ({
                        ...base,
                        py: 1, // Reduce spacing between options
                    }),
                }}
                onChange={handleChange}
                components={customComponents}
            />
        </Box>
    );
};

export default RegionPicker;
