import React, {useEffect, FC} from 'react';
import {Box} from '@chakra-ui/react';
import {useAppDispatch} from "../hooks/useAppDispatch";
import {useAppSelector} from "../hooks/useAppSelector";
import {RootState} from "../store/store";
import {Select, chakraComponents} from "chakra-react-select";
import {fetchTags} from "../store/tags/tagSlice";
import {Tag} from "../types";

// Custom components for Select
const customComponents = {
    Option: (props: any) => (
        <chakraComponents.Option {...props}>
            {props.children}
        </chakraComponents.Option>
    ),
};

// Interface for InterestPickerProps
interface InterestPickerProps {
    onChange: (selectedOptions: any) => void,
    selectedTags: Tag[],
    placeholder: string,
    variant?: string,
    onFocus?: () => void
}

// InterestPicker Component
const InterestPicker: FC<InterestPickerProps> = ({onChange, selectedTags, placeholder, variant, onFocus}) => {
    const dispatch = useAppDispatch();
    const tags = useAppSelector((state: RootState) => state.tag.tags);
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    // Mapping tags to options
    const tagOptions = tags.map(tag => ({
        value: tag,
        label: tag.name,
    }));

    // Fetch tags on component load
    useEffect(() => {
        if (locale && tags.length === 0) {
            dispatch(fetchTags(locale));
        }
    }, [dispatch, locale, tags]);

    // Chakra styles to hide the dropdown icon
    const chakraStyles = {
        dropdownIndicator: (provided: any) => ({
            ...provided,
            display: 'none', // Hide the dropdown arrow
        }),
        placeholder: (provided: any) => ({
            ...provided,
            textAlign: 'left', // Ensure placeholder is left-aligned
        }),
    };

    return (
        <Box width="100%">
            <Select
                name="selectedTags"
                options={tagOptions}
                placeholder={placeholder}  // Use the placeholder prop
                components={customComponents}
                closeMenuOnSelect={false}
                isMulti
                onChange={onChange}
                onFocus={onFocus}
                variant={variant ? variant : "outline"}
                chakraStyles={chakraStyles}  // Apply custom styles to hide the dropdown icon
                defaultValue={tagOptions.filter((option) =>
                    selectedTags.includes(option.value)
                )}
            />
        </Box>
    );
};

export default InterestPicker;
