import React from "react";
import {
    Box,
    Flex,
    IconButton,
    Text,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {getCurrentDate} from "../utils/dateUtils";
import {ColorModeSwitcher} from "../ColorModeSwitcher";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";

export const SimpleNavbar: React.FC = () => {

    const locale = useSelector((state: RootState) => state.locale.locale);

    return (
        <Box width="100%" margin="0" as="header">
            <Flex h={16} alignItems="center" justifyContent="space-between">
                <IconButton
                    icon={<ArrowBackIcon />}
                    aria-label="Back"
                    variant="ghost"
                    onClick={() => window.history.back()}
                />

                <Flex justifyContent="center" gap={["10px", "15px", "20px"]} fontSize={["10px", "12px", "13px"]}>
                    <Text>{getCurrentDate(locale)}</Text>
                </Flex>

                    <ColorModeSwitcher justifySelf="flex-end" />
            </Flex>
        </Box>
    );
};
