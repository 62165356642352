import * as React from "react"
import {
    ChakraProvider,
    Box,
    VStack,
    Grid, Progress
} from "@chakra-ui/react";
import theme from "./theme";

// AWS
import awsExports from './aws-exports';
import {Authenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import {Amplify} from 'aws-amplify';
import { fetchAuthSession } from "aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "./store/store";
import Page from "./pages/Page";
import Notifications from "./components/Notifications/Notifications";

Amplify.configure(awsExports);

const currentConfig = Amplify.getConfig();

console.log(currentConfig)


function App() {
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        fetchAuthSession()
            .then((sessionAttributes) => {
                const tokens = sessionAttributes?.tokens;
                if (tokens && tokens.accessToken && tokens.accessToken.payload) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }

            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <ChakraProvider theme={theme}>
            {isLoading && (
                <>
                    <Box
                        position="fixed"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        bg="rgba(0, 0, 0, 0.8)" // Dimming effect
                        zIndex="999"
                        pointerEvents="none"
                    />
                    <Progress
                        size="xs"
                        colorScheme="red"
                        isIndeterminate
                        position="fixed"
                        top={0}
                        left={0}
                        width="100%"
                        zIndex="1000"
                    />
                </>
            )}
            {isAuthenticated ? (
                <Authenticator>
                    {({ signOut, user }) => (
                        <Box textAlign="center" fontSize="xl">
                            <Grid minH="100vh" p={3}>
                                <VStack spacing={8}>
                                    <Box width={{
                                        base: "95%",
                                        md: "92%",
                                    }}>
                                        <Page user={user} signOut={signOut} />
                                    </Box>
                                </VStack>
                            </Grid>
                        </Box>
                    )}
                </Authenticator>
            ) : (
                <Box textAlign="center" fontSize="xl">
                    <Grid minH="100vh" p={3}>
                        <VStack spacing={8}>
                            <Box width={{
                                base: "95%",
                                md: "92%",
                            }}>
                                <Page />
                            </Box>
                        </VStack>
                    </Grid>
                </Box>
            )}
            <Notifications />
        </ChakraProvider>
    );
}

export default App;
