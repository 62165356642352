/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Safety First (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const selectData = {
  gender: ["Male", "Female", "Other"],

  role: ["USER", "MANAGER"],

  skills: ["react", "vue", "angular", "svelte", "javascript"],
};

export default selectData;
