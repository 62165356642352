import React, {useEffect, FC} from 'react';
import {
    Box,
    Image,
} from '@chakra-ui/react';
import {useAppDispatch} from "../hooks/useAppDispatch";
import {useAppSelector} from "../hooks/useAppSelector";
import {RootState} from "../store/store";

import {Select, chakraComponents} from "chakra-react-select";
import {Category} from "../types";
import {fetchCategories} from "../store/category/categorySlice";


const customComponents = {
    Option: (props: any) => (
        <chakraComponents.Option {...props}>
            {props.data.icon} {props.children}
        </chakraComponents.Option>
    ),
};

interface CategoryPickerProps {
    onChange: (country: Category) => void
    selectedCategoryCode?: string
    onFocus?: () => void
    variant?: string
}

const CategoryPicker: FC<CategoryPickerProps> = ({selectedCategoryCode, onChange, onFocus, variant }) => {
    const dispatch = useAppDispatch();
    const categories = useAppSelector((state: RootState) => state.category.categories);
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const categoryOptions = categories.map(category => ({
        value: category.code,
        label: category.name,
        // icon: (
        //     <Image
        //         src={`https://flagcdn.com/16x12/${category.icon}.png`}
        //         alt={`${category.name} flag`}
        //         mr={2}
        //         h={5}
        //         w={5}
        //     />
        // ),
    }));

    useEffect(() => {
        if (locale && categories.length === 0) {
            dispatch(fetchCategories(locale));
        }
    }, [dispatch, locale]);

    const defaultCountry = categoryOptions.find(
        (option) => option.value === selectedCategoryCode
    ) || null;

    const handleChange = (selectedOption: any) => {
        const category = categories.find(
            (category) => category.code === selectedOption?.value
        ) || null;
        if(category !== null) {
            onChange(category);
        }
    };

    const chakraStyles = {
        dropdownIndicator: (provided: any) => ({
            ...provided,
            display: 'none', // Hide the dropdown arrow
        }),
        placeholder: (provided: any) => ({
            ...provided,
            textAlign: 'left', // Ensure placeholder is left-aligned
        }),
    };

    return (
        <Box width="100%">
            <Select
                name="country"
                options={categoryOptions}
                placeholder="Select a category..."
                components={customComponents}
                onFocus={onFocus}
                defaultValue={defaultCountry}
                onChange={handleChange}
                chakraStyles={chakraStyles}
                variant={variant ? variant : "outline"}
                isClearable
            />
        </Box>
    );
};

export default CategoryPicker;
