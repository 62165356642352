import React from 'react';
import { AuthUser } from "aws-amplify/auth";
import HeadlinesCardView from "../../components/Feed/HeadlinesCardView";

interface BusinessProps {
    user?: AuthUser
    signOut?: any
}

const Technology = ({user, signOut}: BusinessProps) => (
    <>
        <HeadlinesCardView category="TECHNOLOGY"/>
    </>

);

export default Technology;