import {Avatar, Button, Flex, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";
import React from "react";
import { useNavigate } from "react-router-dom";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {signOutUser} from "../../store/auth/authSlice";
import {FaUser} from "react-icons/all";


export const ProfileButton = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    const onLogout = () => {
        dispatch(signOutUser())
            .unwrap() // Use .unwrap() to access the fulfilled or rejected value directly
            .then(() => {
                navigate("/"); // Navigate to the home page on successful logout
            })
            .catch((error) => {
                
                console.error("Logout failed: ", error);
                // Optionally handle error (show toast, alert, etc.)
            });
    };

    return (
        <Flex alignItems={'center'}>
            <Menu>
                <MenuButton
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}>
                    <Avatar
                        size={'sm'}
                        src={
                            'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                        }
                        icon={<Icon as={FaUser} boxSize="5" />}
                    />
                </MenuButton>
                <MenuList py={2}
                          borderBottomRadius="md" // Rounded corners only for bottom
                          borderTopRadius="none" // No rounded corners for top
                >
                    <MenuItem
                        onClick={() => handleNavigation("/profile")}
                        fontWeight="bold"
                        fontSize="15px"
                        color="black">
                        <FormattedMessage id="profile" defaultMessage="Profile" />
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleNavigation("/profile-settings")}
                        fontWeight="bold"
                        fontSize="15px"
                        color="black">
                        <FormattedMessage id="settings" defaultMessage="Settings" />
                    </MenuItem>
                    <MenuItem
                        onClick={onLogout}
                        fontWeight="bold"
                        fontSize="15px"
                        color="black">
                        <FormattedMessage id="logout" defaultMessage="Log Out" />
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    )
}