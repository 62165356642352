import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {NewsByCategory} from "../../types";
import axios from "axios";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import {RootState} from "../store";
interface TopNewsByCategoryState {
    isLoading: boolean;
    data: NewsByCategory[];
    isError: boolean;
    currentPage: number;
    hasMore: boolean;
}

const initialState: TopNewsByCategoryState = {
    isLoading: false,
    data: [],
    isError: false,
    currentPage: 0, // Start from the first page
    hasMore: true,  // Assume there are more pages to fetch initially
};

export const fetchTopNewsByCategory: AsyncThunk<NewsByCategory[], any, any> = createAsyncThunk(
    'news/fetchTopNewsByCategory',
    async ({ locale, page }: { locale: string; page: number }, { dispatch, getState }): Promise<NewsByCategory[]> => {
        try {
            const state = getState() as RootState;
            const { currentPage, data } = state.topNewsByCategory;

            // Check if the current page and locale are the same as in the state
            if (currentPage === page && data.length > 0 && state.locale.locale === locale) {
                return [];  // Return an empty array to indicate no new data was fetched
            }

            dispatch(startLoading());
            const accessToken = state.auth.idToken;
            const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/feed/topCategoryPicks`, {
                headers: headers,
                params: {
                    locale: locale,
                    page: page,
                },
            });

            // If the last page is reached, set `hasMore` to false
            if (response.data.last) {
                dispatch(topNewsByCategorySlice.actions.setHasMore(false));
            }

            return response.data; // Assuming content holds the news array
        } finally {
            dispatch(stopLoading());
        }
    }
);

export const topNewsByCategorySlice = createSlice({
    name: 'news/fetchTopNewsByCategory',
    initialState: initialState,
    reducers: {
        setHasMore(state, action: PayloadAction<boolean>) {
            state.hasMore = action.payload;
        },
        incrementPage(state) {
            state.currentPage += 1;
        },
        resetNewsState(state) {
            state.data = [];
            state.currentPage = 1;
            state.hasMore = true;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTopNewsByCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchTopNewsByCategory.fulfilled, (state, action: PayloadAction<NewsByCategory[]>) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchTopNewsByCategory.rejected, (state, action) => {
                console.error('Evt=news/fetchTopNewsByCategory', action);
                state.isLoading = false;
                state.isError = true;
            });
    }
});

export const { incrementPage, resetNewsState } = topNewsByCategorySlice.actions;
export default topNewsByCategorySlice.reducer;
