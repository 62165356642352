import React from 'react';
import { Box, Grid, Input } from '@chakra-ui/react';

// Define crossword grid type (string array for rows)
const initialBoard: string[][] = [
    ['C', 'A', 'T', '', '', ''],
    ['', '', 'O', '', 'D', 'O', 'G'],
    ['', '', 'W', '', '', 'E'],
];

const Crossword: React.FC = () => {
    const renderBoard = () => {
        return initialBoard.map((row, rowIndex) => (
            <Grid templateColumns={`repeat(${row.length}, 1fr)`} gap={2} key={rowIndex}>
                {row.map((value, colIndex) => (
                    <Input
                        key={colIndex}
                        value={value}
                        readOnly={value !== ''} // Make filled cells read-only
                        placeholder={value === '' ? '' : value}
                        width="50px"
                        height="50px"
                        textAlign="center"
                    />
                ))}
            </Grid>
        ));
    };

    return <Box>{renderBoard()}</Box>;
};

export default Crossword;
