import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAuthHeaders } from "../../utils/authHeaders";
import axios from "axios";

// Define the initial state
interface S3UploadState {
    loading: boolean;
    error: string | null;
    uploadedUrl: string | null;
}

const initialState: S3UploadState = {
    loading: false,
    error: null,
    uploadedUrl: null,
};

// Async thunk for uploading file to S3
export const uploadToS3 = createAsyncThunk<string, File>(
    's3/uploadToS3',
    async (file, { dispatch, rejectWithValue, getState }) => {
        try {
            // Fetch the signed URL from the backend
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/s3/get-signed-url`,
                {
                    params: { fileName: file.name, contentType: file.type },
                    headers: headers,
                }
            );

            const url: string = response.data.url;
            if (!url) {
                throw new Error('Failed to get signed URL');
            }

            // Upload the file to S3 using the signed URL
            await axios.put(url, file, {
                headers: {
                    'Content-Type': file.type,
                },
            });

            // Return the uploaded file's public URL
            return url.split('?')[0]; // Public URL (without query params)
        } catch (error) {
            console.error("Error uploading to S3:", error);
            return rejectWithValue((error as Error).message);
        }
    }
);

const s3Slice = createSlice({
    name: 's3',
    initialState,
    reducers: {
        resetUploadState: (state) => {
            state.loading = false;
            state.error = null;
            state.uploadedUrl = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadToS3.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.uploadedUrl = null;
            })
            .addCase(uploadToS3.fulfilled, (state, action) => {
                state.loading = false;
                state.uploadedUrl = action.payload;
            })
            .addCase(uploadToS3.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { resetUploadState } = s3Slice.actions;
export default s3Slice.reducer;
