
export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const toNormalCase = (text: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export const isNotEmptyString = (str: string) => {
    return str !== null && str !== undefined && str.trim() !== '';
}

export const isEmptyString = (str: string) => {
    return str == null || false || str.trim() == '';
}