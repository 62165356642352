import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Button,
    Input,
    Flex,
    Spacer,
    Stack,
    TagLabel,
    useColorModeValue, Textarea
} from "@chakra-ui/react";

import { debounce } from "lodash";
import ReactMarkdown from "react-markdown";
import InterestPicker from "../InterestPicker";
import {TitleToolTip} from "./TitleToolTip";
import "react-mde/lib/styles/css/react-mde-all.css";
import {ContentToolTip} from "./ContentToolTip";
import {FaBold, FaCode, FaItalic} from "react-icons/all";
import {SimpleNavbar} from "../SimpleNavbar";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {publishPost, saveDraft} from "../../store/post/postSlice";
import {Category, Country, News, Tag} from "../../types";
import CountryPicker from "../CountryPicker";
import {useAppSelector} from "../../hooks/useAppSelector";
import CategoryPicker from "../CategoryPicker";
import useCustomToast from "../../hooks/useCustomToast";
import FileUpload from "../FileUpload";

export const EditorComponent: React.FC = () => {
    const showToast = useCustomToast();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);

    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");
    const [tags, setTags] = useState<Tag[]>([]);
    const [category, setCategory] = useState<Category>();
    const [country, setCountry] = useState<Country>();
    const [focusField, setFocusField] = useState<string>("");
    const [isPreview, setIsPreview] = useState<boolean>(false);

    const handleUploadSuccess = useCallback(
        debounce((fileUrl: string) => {
            console.log("File uploaded:", fileUrl);
            setImageUrl(fileUrl);
            showToast({
                title: "File uploaded successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "bottom-right",
            });
        }, 300),
        [] // Empty array to ensure this function does not change
    );

    useEffect(() => {
        if (user) {
            setCountry(user.country);
        }
    }, [user]);
    const handleSaveDraft = () => {
        // TODO: save to local storage

    };

    const handleTagSelection = (selectedTags: { label: string, value: Tag }[]) => {
        const tags1 = selectedTags.map((option) => option.value);
        setTags(tags1);
    }

    const handlePublishPost = async () => {
        try {
            await dispatch(publishPost({
                title,
                content,
                imageUrl,
                country,
                category,
                tags
            })).unwrap();
            showToast({
                title: "Success",
                description: "Post Saved Successfully",
                status: "success",
                position: "bottom-right",
            });
            window.history.back();
        } catch (error: any) {
            const errorMsg = error.message || "Could not publish post";
            showToast({
                title: "Error!",
                description: errorMsg,
                status: "error",
                position: "bottom-right",
            });
        }
    };

    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const editorBgColor = useColorModeValue('light.background', 'dark.background');  // Light and dark background for editor
    const textColor = useColorModeValue('black', 'white');

    const editorStyles = {
        minHeight: "300px",
        border: "1px solid",
        borderColor: useColorModeValue("gray.300", "gray.700"),
        backgroundColor: editorBgColor,
        color: textColor,
        padding: "10px",
        borderRadius: "5px",
    };

    // Map focus field to advanced tip component
    const advancedTips: Record<string, JSX.Element> = {
        title: <TitleToolTip />,
        tags: <TitleToolTip />,
        content: <ContentToolTip />,
    };

    const applyMarkdown = (syntax: string, offset: number = 0) => {
        const textarea = document.getElementById("editorTextarea") as HTMLTextAreaElement;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = content.substring(start, end);
        const newText = `${content.substring(0, start)}${syntax}${selectedText}${syntax}${content.substring(end)}`;
        setContent(newText);

        // Move cursor inside the syntax if needed
        textarea.setSelectionRange(start + syntax.length + offset, end + syntax.length + offset);
        textarea.focus();
    };


    return (
        <>
            <SimpleNavbar />
            <Flex width="80%" justifyContent="space-between">
                {/* Main editor area */}
                <Box flex="1" p={6} backgroundColor={bgColor} borderRadius="md" boxShadow="lg">
                    <Stack spacing={4}>
                        {/* Cover image */}
                        <Stack direction="row" spacing={4} align="center">
                            <FileUpload onUploadSuccess={handleUploadSuccess}/>
                        </Stack>

                        {/* Title input */}
                        <Input
                            placeholder="New post title here..."
                            fontSize="2xl"
                            fontWeight="bold"
                            variant="unstyled"
                            py={6}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onFocus={() => setFocusField("title")}
                        />

                        {/* Tags input */}
                        <Box>
                            <Flex mb={2}>
                                <InterestPicker onFocus={() => setFocusField("tags")} onChange={handleTagSelection} selectedTags={tags} variant="unstyled" placeholder="Add up to 4 tags..." />
                            </Flex>
                        </Box>

                        {/* Toolbar */}
                        <Flex mb={4}>
                            <Button aria-label="Bold" leftIcon={<FaBold />} onClick={() => applyMarkdown("**", 2)} mr={2}>
                                Bold
                            </Button>
                            <Button aria-label="Italic" leftIcon={<FaItalic />} onClick={() => applyMarkdown("_", 1)} mr={2}>
                                Italic
                            </Button>
                            <Button aria-label="Code" leftIcon={<FaCode />} onClick={() => applyMarkdown("`", 1)} />
                            <Spacer />
                            <Button onClick={() => setIsPreview(!isPreview)}>
                                {isPreview ? "Write" : "Preview"}
                            </Button>
                        </Flex>

                        {/* Markdown editor or preview */}
                        <Box>
                            {/* Markdown editor/preview toggle */}
                            {isPreview ? (
                                <Box p={4} bg={editorBgColor} borderRadius="md">
                                    <ReactMarkdown>{content}</ReactMarkdown>
                                </Box>
                            ) : (
                                <Textarea
                                    id="editorTextarea"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    placeholder="Write your post content here..."
                                    size="md"
                                    height="300px"
                                    bg={editorBgColor}
                                    color={textColor}
                                    p={4}
                                    borderRadius="md"
                                    onFocus={() => setFocusField("content")}
                                />
                            )}
                        </Box>


                        <Box>
                            <Flex mb={2} gap={4}>
                                <CountryPicker onFocus={() => setFocusField("country")} onChange={setCountry} selectedCountryCode={country?.cca2} variant="outline" />
                                <CategoryPicker onFocus={() => setFocusField("category")} onChange={setCategory} selectedCategoryCode={category?.code} variant="outline"/>
                            </Flex>
                        </Box>

                        {/* Action buttons */}
                        <Flex mt={6}>
                            <Button colorScheme="blue" mr={4} onClick={handlePublishPost}>
                                Publish
                            </Button>
                            <Button onClick={handleSaveDraft}>Save draft</Button>
                        </Flex>
                    </Stack>
                </Box>


                {/* Tips section */}
                <Box p={6} width={'30%'}>
                    {focusField && (
                        <Box p={4}>
                            {advancedTips[focusField]}
                        </Box>
                    )}
                </Box>
            </Flex>
        </>
    );
};
