import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {WorldRegion} from "../../types";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import {fetchAuthHeaders} from "../../utils/authHeaders";
import {RootState} from "../store";

interface RegionState {
    regions: WorldRegion[];
    currentRegion: WorldRegion | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: RegionState = {
    regions: JSON.parse(localStorage.getItem('regions') || '[]'),
    currentRegion: JSON.parse(localStorage.getItem('currentRegion') || 'null'),
    isLoading: false,
    error: null,
};

export const fetchRegions = createAsyncThunk(
    'country/fetchRegions',
    async (locale: string, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/world-regions`,{
                headers: {...headers, "Accept-Language": locale},
            });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to fetch regions');
        }
        finally {
            dispatch(stopLoading());
        }
    }
);

export const regionSlice = createSlice({
    name: 'regions',
    initialState,
    reducers: {
        setRegion: (state, action: PayloadAction<WorldRegion>) => {
            state.currentRegion = action.payload;
            localStorage.setItem('currentRegion', JSON.stringify(action.payload));
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRegions.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchRegions.fulfilled, (state, action: PayloadAction<WorldRegion[]>) => {
                state.isLoading = false;
                state.regions = action.payload;
                localStorage.setItem('regions', JSON.stringify(action.payload));
            })
            .addCase(fetchRegions.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
    },
});

export const { setRegion } = regionSlice.actions;
export const selectCurrentRegion = (state: RootState) => state.region.currentRegion;
export default regionSlice.reducer;
