import React, { useState } from "react";
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    Icon,
    Stack,
    FormControl,
    FormLabel,
    FormErrorMessage,
    useTheme,
    useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import useCustomToast from "../../hooks/useCustomToast";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { confirmSignUpUser, regenerateVerificationCode } from "../../store/auth/authSlice";

export const Verification = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;
    const dispatch = useAppDispatch();
    const showToast = useCustomToast();

    const [verificationCode, setVerificationCode] = useState("");
    const [error, setError] = useState("");

    const handleVerification = () => {
        if (!verificationCode || verificationCode.length !== 6) {
            setError("Please enter a valid 4-digit verification code.");
            return;
        }
        setError("");

        dispatch(confirmSignUpUser({ username: email, code: verificationCode }))
            .unwrap()
            .then(() => {
                showToast({
                    title: "Success!",
                    description: "Your email has been verified.",
                    status: "success",
                    position: "bottom-right",
                });
                navigate("/login");
            })
            .catch((error: { message: any; }) => {
                showToast({
                    title: "Error",
                    description: error.message || "Verification failed.",
                    status: "error",
                    position: "bottom-right",
                });
            });
    };

    const handleRegenerateCode = () => {
        dispatch(regenerateVerificationCode({ email }))
            .unwrap()
            .then(() => {
                showToast({
                    title: "Success!",
                    description: "A new verification code has been sent to your email.",
                    status: "success",
                    position: "bottom-right",
                });
            })
            .catch((error: { message: any; }) => {
                showToast({
                    title: "Error",
                    description: error.message || "Failed to regenerate code.",
                    status: "error",
                    position: "bottom-right",
                });
            });
    };

    const boxPadding = useBreakpointValue({ base: "10px", md: "20px" });

    return (
        <Box width="100%" margin="0 auto" padding="20px">
            <Box
                width="100%"
                margin="0 auto"
                border={`3px solid ${theme.colors.gray[300]}`}
                borderRadius="20px"
                padding={boxPadding}
                maxWidth="500px"
            >
                <Text fontSize="24px" textAlign="center" mb={6}>
                    Enter Verification Code
                </Text>
                <Stack spacing={6}>
                    <FormControl isInvalid={!!error}>
                        <FormLabel>Verification Code</FormLabel>
                        <Input
                            type="text"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            maxLength={6}
                        />
                        <FormErrorMessage>{error}</FormErrorMessage>
                    </FormControl>
                    <Button
                        bg={"blue.400"}
                        color={"white"}
                        onClick={handleVerification}
                        _hover={{ bg: "blue.500" }}
                    >
                        Verify
                    </Button>
                    <Text color="#6c798f" textAlign="center" fontSize="15px">
                        Didn't receive a code?
                    </Text>
                    <Button
                        variant="outline"
                        colorScheme="gray"
                        onClick={handleRegenerateCode}
                    >
                        Resend Code
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};
