import {Box, useColorModeValue} from "@chakra-ui/react";
import avatar4 from "../../assets/img/avatars/avatar4.png";
import React, {useEffect} from "react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import Header from "./components/Header";
import Projects from "./components/Projects";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {fetchUserDetails} from "../../store/user/userSlice";
import {RootState} from "../../store/store";

function Profile() {
    const dispatch = useAppDispatch();

    const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

    const { user } = useAppSelector((state) => state.user);
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    useEffect(() => {
        dispatch(fetchUserDetails(locale)); // Pass the locale as needed
    }, [dispatch]);

  return (
    <Box>
        <Header
            backgroundProfile={bgProfile}
            avatarImage={avatar4}
            name={user?.name || ""} // Fallback name if user is undefined
            email={user?.email || ""} // Fallback email if user is undefined

            tabs={[
                {
                    name: "OVERVIEW",
                    icon: <FaCube width='100%' height='100%' />,
                },
                {
                    name: "TEAMS",
                    icon: <FaCube width='100%' height='100%' />,
                },
                {
                    name: "PROJECTS",
                    icon: <FaPenFancy width='100%' height='100%' />,
                },
            ]}
        />
      <Projects title={"Projects"} description={"Architects design houses"} />
    </Box>
  );
}

export default Profile;
