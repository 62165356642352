import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import React from "react";

export const ContentToolTip = () => {
    return (
        <Box>
            <Heading as="h4" size="md" mb={3}>
                Editor Basics
            </Heading>
            <UnorderedList ml={4} listStyleType="disc">
                <ListItem>
                    <Text fontSize="sm" textAlign="justify">
                        Use Markdown to write and format posts.
                    </Text>
                </ListItem>
                <ListItem>
                    <Text fontSize="sm" textAlign="justify">
                        Don't forget to add tags and a cover image to your post.
                    </Text>
                </ListItem>
            </UnorderedList>
        </Box>
    );
};
